import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { Link } from "gatsby";
import SEO from "../components/seo";
import { FaBook, FaQuestion } from "react-icons/fa";
import styles from "./rodo.module.css";

const ONas = ({ data }) => {
  return (
    <Layout>
      <SEO title="RODO i CRM - co powinieneś wiedzieć? - Prosty CRM" description="RODO i CRM. Co warto wiedzieć? Jak zabezpieczyć dane osobowe klientów? Sprawdź!" />
      <Hero maxHeight="200px" justifyContent="center">
        <h1>RODO I CRM</h1>
      </Hero>
      <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "column"
        }}
        className="container"
      >
        <h2>Program CRM i wymogi RODO:</h2>
        <p>
          Korzystając z prostego systemu CRM z pewnością operujesz danymi swoich
          klientów. Jeśli chcesz robić to zgodnie z nowymi przepisami, które
          wprowadza RODO, zapoznaj się z wymogami, które stawia. Specjalnie dla
          Państwa przygotowaliśmy zbiór treści, które pomogą rozwiać wszelkie
          wątpliwości w zakresie unijnego rozporządzenia i jego wpływu na pracę
          z systemem CRM. Zestaw gotowych odpowiedzi i ciekawych artykułów
          pomoże we wprowadzaniu ewentualnych zmian w działaniu firmy.
        </p>
        <div className={styles.rodoCards}>
          <div className={styles.rodoCard}>
            <div className={styles.rodoIcon}>
              <FaBook size="50px" />
            </div>
            <h3>Baza wiedzy o RODO w CRM</h3>
           
            <Link className={styles.rodoButton} to="/dane-osobowe-w-crm/">
              Dane osobowe w CRM
            </Link>
          </div>
          <div className={styles.rodoCard}>
            <div className={styles.rodoIcon}>
              <FaQuestion size="50px" />
            </div>
            <h3>FAQ o RODO</h3>
            <Link className={styles.rodoButton} to="/faq-rodo/">
              Przejdź do FAQ
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ONas;
